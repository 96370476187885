
@import "~lessRoot/_mixins";

.dxp-toyland-signup-widget {
	background-color: #01305F;
	border-radius: 8px;
	color: white;
	padding: 24px 70px;
	display: flex;
	gap: 70px;
	align-items: center;
	min-height: 170px;

	img {
		width: 100%;
		max-width: 400px;
	}

	h3 {
		font-weight: bold;
		margin-bottom: 5px;
	}

	p {
		font-size: 14px;
	}

	&__content {
		flex: 1;

		.error-text {
			font-size: .75rem;
			line-height: 1.33;
			padding-top: 4px;
			font-style: italic;

			&--mobile {
				display: none;
			}
		}
	}

	&__form {
		display: flex;
		gap: 15px;
	}

	&__input-group {
		width: 100%;
		max-width: 600px;
		position: relative;

		.error-icon {
			color: #b12f31;
			font-size: 24px;
			position: absolute;
			right: 10px;
			top: 12px;

		}
	}

	&__input {
		height: 48px;
		width: 100%;
		font-size: 16px;
		padding: 10px;
		border-radius: 3px;
		border: 1px solid #656E96;

		&.error {
			border-bottom: 3px solid #b12f31;
		}
	}

	&__input::placeholder {
		color: #656E96;
	}

	&__button {
		border: none;
		width: 250px;
		height: 48px;
	}

	.tablet-styles({
		padding: 20px 40px;

		img {
			max-width: 300px;
		}

		h3 {
			font-size: 20px
		}
	});

	.mobile-styles({
		height: auto;
		gap: 30px;
		flex-direction: column;
		padding: 20px 15px;
		text-align: center;

		img {
			margin: 0 auto;
			max-width: 210px;
		}

		h3 {
			font-size: 18px
		}

		&__content {
			text-align: center;

			.error-text {
				&--mobile {
					text-align: left;
					display: block;
				}

				&--desktop {
					display: none;
				}
			}
		}

		&__form {
			flex-direction: column;
			gap: 10px;
		}

		&__input-group {
			max-width: none;
		}

		&__button {
			max-width: 100%;
			width: 100%;
		}
	});

}
