
@import "~lessRoot/_mixins";
.dxp-dog-header {

	display: grid;
	grid-template-columns: 1fr;
	background-color: #01305F;

	.tablet-and-up-styles({
		grid-template-columns: 1fr 1fr;
	});

	&__content {
		order: 2;
		padding-top: 10px;

		.tablet-and-up-styles({
			order: 1;
			padding-top: 100px;
		});

		.desktop-min-only-styles({
			padding-left: 15px;
		});
	}

	&__image {
		order: 1;
		height: 225px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: var(--hcth-mobile-image);

		.tablet-and-up-styles({
			order: 2;
			height: 100%;
			background-image: var(--hcth-tablet-image);
		});

		.desktop-min-only-styles({
			background-image: var(--hcth-desktop-image);
		});

		&::after {
			content: '';
			display: block;
			height: 100%;
			background: transparent linear-gradient(180deg, #01305F00 50%, #01305F 100%) 0% 0% no-repeat padding-box;

			.tablet-and-up-styles({
				background: transparent linear-gradient(-90deg, #01305F00 50%, #01305F 95%) 0% 0% no-repeat padding-box;
			});
		}
	}

	&__heading {
		color: #fff;
		margin-bottom: 10px;
		font-size: 1.75rem;
		line-height: 1.14;
		font-weight: 500;

		.desktop-min-only-styles({
			font-size: 2.25rem;
			line-height: 1.11;
		});
	}

	&__subheading {
		color: #fff;
		font-size: 1.125rem;
		line-height: 1.44;
		font-weight: normal;
		margin-bottom: 15px;

		.desktop-min-only-styles({
			font-size: 1.5rem;
			line-height: 1.5;
		});
	}

	&__subcategories {
		width: 100%;
		z-index: 2;
		position: relative;

		.tablet-and-up-styles({
			width: 160%;
		});
	}

	&__line {
		max-width: 720px;
		padding: 0 10px 30px 15px;
		position: relative;
		z-index: 2;

		.tablet-and-up-styles({
			width: 105%;
			margin: 0 0 0 auto;
			padding: 0 5px 40px 40px;
		});

		.desktop-min-only-styles({
			border-left: 4px solid #fff;
			padding: 0 5px 40px 60px;
		});
	}

	&__subcat {
		display: inline-block;
		width: auto;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: 500;
		margin-right: 10px;
		margin-top: 10px;
		font-size: 0.875rem;
		text-wrap: nowrap;

		.tablet-and-up-styles({
			margin-right: 15px;
			margin-top: 15px;
			font-size: 1rem;
		});
	}
}
