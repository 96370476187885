
@import "~lessRoot/_mixins";

.carousel-arrow-button {
	position: relative;
	color: var(--carousel-arrow-button-color, @BB);
	border-radius: 50%;
	font-size: 1.375rem;
	padding: 12px;

	// Because the border size changes, we apply it to this pseudo-element instead to avoid reflow.
	// This was previously done by adjusting the button padding along with the border width, but this
	// approach caused occasional mismatches in browser size calculations, (esp. when zoomed) that
	// caused the button width to change. For layouts like the TrendingCarouselWidget, this resize
	// would _also_ cause the carousel viewport size to change. ListSliderElement's ResizeObserver would then
	// detect this slightly change in size, trigger a reset, and thus break the carousel whenever one of these
	// buttons was hovered
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		inset: 0;
		border: 2px solid currentColor;
	}

	&:not([aria-disabled="true"], :disabled) {
		&:hover, &:active {
			&::after {
				border-width: 3px;
			}
		}

		&:active {
			&::after {
				border-width: 4px;
			}
		}
	}

	&[aria-disabled="true"], &:disabled {
		color: tint(@CL, 20%);
		cursor: not-allowed;
	}

	&__icon {
		display: block;
		width: 1em;
		height: 1em;
	}
}
